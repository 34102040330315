$statement-bg: #194172;
//#194172 default:#0f2a4a
$statement-htc: #a1ce57;

.statement {
   .card-header {
      color: #a1ce57;
   }
   table {
      background-color: $statement-bg;
      thead {
         color: $statement-htc !important;
      }
      th {
         border-color: rgba(255, 255, 255, 0.1);
         padding: 0.8rem;
      }
      td {
         border-color: rgba(255, 255, 255, 0.1);
         color: white;
         padding: 0.8rem;
      }
      border-color: rgba(255, 255, 255, 0.1);
      &.table-striped {
         tbody {
            tr:nth-of-type(odd) {
               background-color: rgba(255, 255, 255, 0.06);
            }
         }
      }
      &.table-hover {
         > tbody > tr:hover {
            background-color: lighten($statement-bg, 8);
         }
      }
   }
   .page-item {
      .page-link {
         background-color: $statement-bg;
         color: $statement-htc;
         border-color: rgba(255, 255, 255, 0.1);
         &:active,
         &:hover {
            background-color: lighten($color: $statement-bg, $amount: 5);
         }
      }
      &.disabled {
         .page-link {
            background-color: $statement-bg;
            color: gray;
         }
      }
      &.active {
         .page-link {
            color: $statement-bg;
            background-color: $statement-htc;
         }
      }
   }
   .form-control {
      color: $statement-bg;
      background-color: $statement-htc;
      border-color: rgba(255, 255, 255, 0.1);
      border-radius: 0%;
   }
   .input-group-text {
      background-color: $statement-bg;
      color: gray;
      border-color: rgba(255, 255, 255, 0.1);
      border-radius: 0%;
   }
   background-color: $statement-bg;
}
