$admin-panel-bg: #194172;
//#194172 default:#0f2a4a
$admin-panel-htc: #a1ce57;

.admin-panel {
   .card-header {
      color: #a1ce57;
   }
   table {
      background-color: $admin-panel-bg;
      thead {
         color: $admin-panel-htc !important;
      }
      th {
         border-color: rgba(255, 255, 255, 0.1);
         padding: 0.8rem;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         background-color: $admin-panel-bg;
      }
      td {
         border-color: rgba(255, 255, 255, 0.1);
         color: white;
         padding: 0.8rem;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         background-color: inherit;
      }
      border-color: rgba(255, 255, 255, 0.1);
      &.table-striped {
         tbody {
            tr:nth-of-type(odd) {
               background-color: darken($admin-panel-bg, 4);
            }
            tr:nth-of-type(even) {
               background-color: $admin-panel-bg;
            }
         }
      }
      &.table-hover {
         > tbody > tr:hover {
            background-color: lighten($admin-panel-bg, 8);
         }
      }
   }
   .page-item {
      .page-link {
         background-color: $admin-panel-bg;
         color: $admin-panel-htc;
         border-color: rgba(255, 255, 255, 0.1);
         &:active,
         &:hover {
            background-color: lighten($color: $admin-panel-bg, $amount: 5);
         }
      }
      &.disabled {
         .page-link {
            background-color: $admin-panel-bg;
            color: gray;
         }
      }
      &.active {
         .page-link {
            color: $admin-panel-bg;
            background-color: $admin-panel-htc;
         }
      }
   }
   .form-control {
      color: $admin-panel-bg;
      background-color: $admin-panel-htc;
      border-color: rgba(255, 255, 255, 0.1);
      border-radius: 0%;
   }
   .input-group-text {
      background-color: $admin-panel-bg;
      color: gray;
      border-color: rgba(255, 255, 255, 0.1);
      border-radius: 0%;
   }
   background-color: $admin-panel-bg;
}

[data-sticky-td] {
   position: sticky;
   background-color: inherit;
   overflow: visible !important;
}

[data-sticky-last-left-td] {
   box-shadow: 2px 0px 4px darken($admin-panel-bg, 20);
}

[data-sticky-first-right-td] {
   box-shadow: -2px 0px 4px darken($admin-panel-bg, 20);
}
