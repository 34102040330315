.active {
   //border-top: thick solid #a1ce57;
   color: #a1ce57 !important;
   > .dropdown-item {
      color: white;
      background-color: #194172;
   }
}

.custom-nav-link {
   display: flex !important;
   justify-content: center;
   align-items: center;
   font-weight: bold;
   color: #194172;
   &:hover {
      //background-color: rgba($color: #000000, $alpha: 0.1);
      color: #a1ce57;
      transform: translateY(-5px);
      //border-top: thick solid #a1ce57;
      transition: all 0.4s;
   }
   &:active {
      color: #007bff;
   }
   span {
      vertical-align: middle;
   }
}
